.modal {
  &_wrap {
    @include flex;
    @include flex-justify(center);
    @include flex-align(center);
    @include position(0, 0, 0, 0, $Z-index-2, fixed);
    background-color: rgba(0, 0, 0, 0.6);
  }
  &_container {
    overflow: hidden;
    width: 312px;
    max-width: 312px;
    //min-height: 172px;
    border-radius: 8px;
    background: $C-white;
  }
  &_content {
    @include flex;
    @include flex-align(center);
    padding: 32px 20px;
  }
  &_footer {
    @include flex;
    .btn_default,
    .btn_primary{
      height: 52px;
      @include fontStyleGroup(null, $F-size, $F-weight-regular, 24px, null, null);
    }
    .btn_default {
      flex: 1;
      background: $C-gray-200;
      color: $C-gray-900;
    }
    .btn_primary {
      flex: 1;
      background: $C-primary-500;
      color: $C-white;

      .timer {
        display: inline-block;
        overflow: hidden;
        position: relative;
        width: 18px;
        height: 18px;
        line-height: 20px;
        vertical-align: -4px;
        text-align: center;
        font-family: Roboto sans-serif;
        font-size: $F-size-xsmall;

        &::before {
          @include position(0, 0, 0, 0);
          content: '';
          border: 1px solid $C-white;
          border-top-color: #8da9e1;
          border-radius: 50%;
          animation: rotate 1s linear infinite;
        }
      }
    }
  }
  &_alert {
    flex: 1;
    text-align: center;

    &_title {
      @include fontStyleGroup(null, $F-size-big, $F-weight-regular, 24px, null, $C-gray-900);
      word-break: break-word;
    }
    &_msg {
      margin-top: 12px;
      @include fontStyleGroup(null, $F-size-normal, $F-weight-regular, 20px, null, $C-gray-500);
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}