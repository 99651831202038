@charset "UTF-8";

// flex default
%flexbox {
  @include flex();
}

%flexbox-stretch {
  @include flex();
  @include flex-grow(1);
}

%flexbox-stretch-noshrink {
  @include flex();
  @include flex-grow(1, 0);
}

%flexbox-v {
  @include flex();
  @include flex-direction(column);
}

%flexbox-stretch-v {
  @include flex();
  @include flex-grow(1);
  @include flex-direction(column);
}

%flexbox-stretch-noshrink-v {
  @include flex();
  @include flex-grow(1, 0);
  @include flex-direction(column);
}

%flexbox-fix {
  @include flex();
  @include flex-grow(0, 0);
}

%flexbox-fix-v {
  @include flex();
  @include flex-grow(0, 0);
  @include flex-direction(column);
}

%flex-center {
  @include flex();
  @include flex-justify(center);
}
%flex-between {
  @include flex();
  @include flex-justify(space-between);
}
%flex-around {
  @include flex();
  @include flex-justify(space-around);
}
%btn-disabled {
  &:disabled,
  &.disabled {
    @include opacity(0.3);
    cursor: default;
  }
}

// disabled
%disabled {
  opacity: 0.4;
}

// iphone x
%iphone-padding-bottom {
  &:after {
    display: block;
    font-size: 0;
    line-height: 0;
    padding-top: 0;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    content: '';
  }
}
