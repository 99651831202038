@charset "UTF-8";

//icon
.ico{
  &_face_sad{
    @include use-svg(face_cry_1, $ICON_SIZE_64, $ICON_SIZE_64, 0 0, flex, $wrap-position: relative, $position: relative);
  }
  &_face_smile{
    @include use-svg(face_cry_2, $ICON_SIZE_64, $ICON_SIZE_64, 0 0, flex, $wrap-position: relative, $position: relative);
  }
  &_discount{
    @include use-svg(ico_discount, $ICON_SIZE_40, $ICON_SIZE_40, 0 0, flex, $wrap-position: relative, $position: relative);
  }
  &_car{
    @include use-svg(ico_car, $ICON_SIZE_40, $ICON_SIZE_40, 0 0, flex, $wrap-position: relative, $position: relative);
  }
  &_minus{
    @include use-svg(ico_minus, $ICON_SIZE_26, $ICON_SIZE_26, 0 0, block, $wrap-position: relative, $position: relative);
  }
  &_plus{
    @include use-svg(ico_plus, $ICON_SIZE_26, $ICON_SIZE_26, 0 0, block, $wrap-position: relative, $position: relative);
  }
  &_arrow_right{
    @include use-svg(ico_arrow_right, $ICON_SIZE_20, $ICON_SIZE_20, 50% 20px, block, $wrap-position: relative, $type: right);
  }
}