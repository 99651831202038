@charset "UTF-8";

// settings
.settings {
  &_wrap{
    width: 100%;
    height: 100vh;
    background: $C-gray-100;
    padding: $G-header-height 0 0;
  }
  .button_group{
    padding: 1rem 0 0;
  }
}
