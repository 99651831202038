@charset "UTF-8";

// discount main, submit
.discount {
  &_wrap{
    width: 100%;
    padding: $G-header-height 0 calc(#{$G-footer-height} + 1rem);
  }
  &_title{
    padding: 1.6rem 1.6rem 3rem;
    text-align: left;
    @include fontStyleGroup(null, $F-size-xsbigger, $F-weight-medium, 140%, null, $C-gray-900);
  }

  &_coupon{
    padding: 0 1.6rem 4rem;
  }
  .no_coupon{
    padding: 8rem 0;
  }

  &_submit{
    padding: 0 1.6rem 2.2rem;
    &_title{
      padding-bottom: 2.2rem;
      @include fontStyleGroup(null, $F-size-xxbig, $F-weight-bold, 34px, null, $C-tmobi-blue-400);
    }
    &_date{
      padding-bottom: 2rem;
    }
    &_total{
      border-top: 1px solid $C-gray-200;
      margin: 0 1.6rem 0;
      padding: 2rem 0;
      @include flexGroup(col, space-between, null);
      gap: 1.2rem 0;
      .total{
        &_price,
        &_detail{
          @include flexGroup(row, space-between, null);
        }
        &_price{
          @include fontStyleGroup(null, $F-size-big, $F-weight-regular, 23px, null, $C-gray-900);
        }
        &_detail{
          gap: 2rem 0;
          @include fontStyleGroup(null, $F-size, $F-weight-regular, 20px, null, $C-gray-500);
          .title,
          .qty{
            color: inherit;
          }
        }
      }
    }
  }

  .button_group{
    background: $C-white;
  }
}

.submit{
  .button_group{
    background: $C-white;
    .btn_round_default{
      width: 104px;
      flex: none;
    }
  }
}
