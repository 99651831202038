@charset "UTF-8";

// coupon item
.coupon{
  padding: 2.4rem 1.6rem 4rem;
  &_list{
    @include flexGroup(col, flex-start, center);
    gap: 1rem 0;
  }
  &_title{
    padding-bottom: 1.8rem;
    @include fontStyleGroup(null, $F-size, $F-weight-bold, 22px,  $color: $C-gray-900);
  }
  &_date{
    @include flexGroup(row, flex-start, center);
    gap: 0 1rem;
    padding-bottom: 1.4rem;
    @include fontStyleGroup(null, $F-size-normal, $F-weight-regular, 14px,  $color: $C-gray-500);
  }
  &_item{
    background: $C-white;
    border: 1px solid $C-gray-200;
    box-shadow: 0px 0px 8px 1px rgba(238, 240, 243, 0.8);
    border-radius: 16px;
    @include flexGroup(row, space-between, center);
    gap: 0 1rem;
    padding: 2rem;
    width: 100%;
    @extend .ico_discount;

    .price{
      @include flexGroup(col, flex-start, flex-start);
      flex: 2;
      @include fontStyleGroup(null, $F-size, $F-weight-bold, 22px, $color: $C-gray-900);
      &_title{
        @include fontStyleGroup(null, $F-size-xsmall, $F-weight-regular, 18px,  $color: $C-gray-600);
      }
    }
    .qty{
      @include fontStyleGroup(null, $F-size-xsbigger, $F-weight-regular, 22px,  $color: $C-gray-500);
    }

    &.selected{
      border: 1px solid $C-tmobi-blue-400;
    }

    &.type_car{
      @extend .ico_car;
      justify-content: flex-start;
      .price{
        flex: none;
      }
      .tag_discount{
        background: $C-tmobi-pink-200;
        border-radius: 0.4rem;
        padding: 0.1rem 0.4rem;
        @include fontStyleGroup(null, $F-size-xsmall, $F-weight-regular, 18px, $color: $C-tmobi-pink-600);
      }
    }
  }

  &_qty{
    &_list{
      @include flexGroup(col, null, null);
      gap: 2rem 0;
    }
    &_item{
      @include flexGroup(row, space-between, center);
      flex-wrap: wrap;
      .qty{
        &_display{
          @include flexGroup(row, space-between, center);
          gap: 0 1rem;
          @include fontStyleGroup(null, $F-size, $F-weight-regular, 22px,  $color: $C-gray-900);
        }
        &_value{
          width: 3.3rem;
          text-align: center;
          //padding: 0 1.2rem;
        }
        &_setting{
          background: $C-gray-200;
          border-radius: 4px;
          @include flexGroup(row, space-between, center);
          @include fontStyleGroup(null, $F-size-xsmall, $F-weight-bold, 17px,  $color: $C-gray-900);
        }
      }
    }
  }
}

.no_coupon{
  text-align: center;
  padding: 6.8rem 0;
  @include fontStyleGroup(null, $F-size, $F-weight-regular, 22px, $color: $C-gray-600);
  @include flexGroup(col, null, center);
  gap: 1.63rem 0;
}