@charset "UTF-8";

// main
.main {
  &_notice{
    padding: 0.8rem 1.4rem 0 1.6rem;
    @include flexGroup(row, space-between, center);

    &_title{
      width: calc(100% - #{$ICON_SIZE_28} - 1.2rem);
      background-color: $C-tmobi-blue-200;
      @include flexGroup(row, null, center);
      @include fontStyleGroup(null, $F-size-xsmall, $F-weight-regular, 19px, $color: $C-gray-600);
      gap:0 0.4rem;
      @include use-svg(ico_place_convenience, $ICON_SIZE_28, $ICON_SIZE_28, 0 0, flex, $position: relative);
      border-radius: 0.8rem;
      padding: 0.6rem 0.8rem;
      margin-right: 1.2rem;
    }
  }

  &_title{
    padding: 3.2rem 1.6rem 2.4rem;
    text-align: left;
    @include fontStyleGroup(null, $F-size-xsbigger, $F-weight-medium, 140%, null, $C-gray-900);
  }

  &_form{
    width: 100%;
    padding: 0 1.6rem 4rem;
    @include flexGroup(row, space-between, center);
    gap: 0 1rem;
    border-bottom: 0.8rem solid $C-gray-100;

    .btn_round_primary{
      height: 6.8rem;
      padding: 2.2rem 2.8rem;
      font-weight: $F-weight-regular;
    }
  }

  &_coupon{
    padding: 2.4rem 1.6rem 4rem;
    &_title{
      padding-bottom: 1.8rem;
      @include fontStyleGroup(null, $F-size-big, $F-weight-medium, 24px,  $color: $C-gray-900);
    }
  }
}
