@charset "UTF-8";

// login
.login {
  &_wrap{
    width: 100%;
    padding: $G-header-height 0 0;
  }

  &_title{
    padding: 1.6rem 1.6rem 3.5rem;
    text-align: left;
    @include fontStyleGroup(null, $F-size-xsbigger, $F-weight-reset, 140%, null, $C-gray-900);
  }

  &_form{
    width: 100%;
    padding: 0 2rem 2rem;
  }
}
