@charset "UTF-8";

.float_l {
  float: left;
}
.float_r {
  float: right;
}

.mt0 {
  margin-top: 0 !important;
}
.mt5 {
  margin-top: 5px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt15 {
  margin-top: 15px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt24 {
  margin-top: 24px !important;
}
.mt25 {
  margin-top: 25px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt32 {
  margin-top: 32px !important;
}
.mt35 {
  margin-top: 35px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.mt48 {
  margin-top: 48px !important;
}
.mt50 {
  margin-top: 50px !important;
}
.mt60 {
  margin-top: 60px !important;
}
.mt70 {
  margin-top: 70px !important;
}
.mt100 {
  margin-top: 100px !important;
}
.mmt5 {
  margin-top: -5px !important;
}
.mmt10 {
  margin-top: -10px !important;
}
.mmt15 {
  margin-top: -15px !important;
}
.mmt20 {
  margin-top: -20px !important;
}
.mmt25 {
  margin-top: -25px !important;
}
.mmt30 {
  margin-top: -30px !important;
}
.mmt48 {
  margin-top: -48px !important;
}
.mr4 {
  margin-right: 4px !important;
}
.mr5 {
  margin-right: 5px !important;
}
.mr6 {
  margin-right: 6px !important;
}
.mr8 {
  margin-right: 8px !important;
}
.mr10 {
  margin-right: 10px !important;
}
.mr15 {
  margin-right: 15px !important;
}
.mr20 {
  margin-right: 20px !important;
}
.mr30 {
  margin-right: 30px !important;
}
.ml4 {
  margin-left: 4px !important;
}
.ml5 {
  margin-left: 5px !important;
}
.ml8 {
  margin-left: 8px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.ml15 {
  margin-left: 15px !important;
}
.ml20 {
  margin-left: 20px !important;
}
.ml30 {
  margin-left: 30px !important;
}
.ml40 {
  margin-left: 40px !important;
}
.ml70 {
  margin-left: 70px !important;
}
.mb0 {
  margin-bottom: 0 !important;
}
.mb5 {
  margin-bottom: 5px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb15 {
  margin-bottom: 15px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb25 {
  margin-bottom: 25px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
.mb60 {
  margin-bottom: 60px !important;
}
.pl10 {
  padding-left: 10px;
}
.pr20 {
  padding-right: 20px;
}
.pr40 {
  padding-right: 40px;
}
.pb20 {
  padding-bottom: 20px;
}
.w400 {
  width: 400px !important;
}
.wp30 {
  width: 30% !important;
}
.wp33 {
  width: 33% !important;
}
.wp35 {
  width: 35% !important;
}
.wp40 {
  width: 40% !important;
}
.wp45 {
  width: 45% !important;
}
.wp50 {
  width: 50% !important;
}
.wp55 {
  width: 55% !important;
}
.wp60 {
  width: 60% !important;
}
.wp70 {
  width: 70% !important;
}

.wp100{
  width: 100% !important
}

.vh100 {
  height: 100vh;
}

.type {
  //align
  &_center {
    text-align: center !important;
  }
  &_left {
    text-align: left !important;
  }
  &_right {
    text-align: right !important;
  }
  &_bottom {
    @include flex-align(flex-end);
  }
  &_auto {
    width: auto;
    margin: 0 auto;
  }
  &_bold{
    font-weight: $F-weight-bold;
  }

  &_blue-400{
    color: $C-tmobi-blue-400 !important;
  }

  // layout
  &_abs {
    position: absolute;
    z-index: 20;
  }
  &_relative {
    position: relative;
    z-index: 20;
  }
  &_position_unset {
    position: unset;
    z-index: 20;
  }
  &_fixed_bottom{
    @include position(null, null, 0, 0, 20, fixed)
  }

  // focus
  &_focus {
    position: initial;
    transform: translateY(0);
  }
  // button
  &_on {
    opacity: 1;
  }
  &_off {
    opacity: 0.0001;
    pointer-events: none;
  }

  // text
  &_nowrap {
    white-space: nowrap;
  }
  &_word {
    word-break: keep-all;
    word-wrap: break-word;
  }

  // display
  &_flex {
    //@extend %flex;
    display: flex !important;
  }
  &_flex-col {
    flex-direction: column !important;
  }
  &_flex-center {
    @extend %flex-center;
  }
  &_flex-align-end {
    align-items: flex-end !important;
  }
  &_block {
    display: block;
  }
  &_ib {
    display: inline-block;
  }
  &_none {
    display: none !important;
  }
  &_visible {
    opacity: 1;
  }
  &_disable {
    .content {
      overflow: hidden;
      pointer-events: none;
    }
  }

  // event
  &_prevent-event {
    pointer-events: none;
  }

  &_preserve-wrap {
    white-space: pre-wrap;
    word-break: normal;
  }

  &_break-spaces {
    white-space: break-spaces;
  }

  &_break_all {
    word-break: break-all;
  }
}
