@charset "UTF-8";

//Toast
.toast{
  &_wrap{
    position: fixed;
    z-index: 9999999;
    bottom: 0;
    width: 100%;
    text-align: center;
    margin: 0 2rem;
  }
  &_message{
    position: absolute;
    width: calc(100% - 4rem);
    left: 0;
    bottom: 80px;
    @include fontStyleGroup(null, $F-size, $F-weight-medium, 22px, null, $C-white);
    background: rgba(17, 17, 17, 0.8);
    border-radius: 5px;
    visibility: hidden;
    padding: 1rem 0;
    &.active{
      visibility: visible;
    }

    &.fade {
      &-enter,
      &-appear,
      &-exit-done {
        opacity: 0;
        transform: translate(0, 30%) !important;
        transition: all 100ms ease-out;
      }
      &_appear-active,
      &-enter-active {
        opacity: 1;
        transform: translate(0, 0) !important;
        transition: all 100ms ease-out;
      }
      &-enter-done,
      &-exit {
        opacity: 1;
        transform: translate(0, 0) !important;
        transition: all 100ms ease-out;
      }
      &-exit-active {
        opacity: 0;
        transform: translate(0, 30%) !important;
        transition: all 100ms ease-out;
      }
    }
  }
}
