@charset "UTF-8";

// input

.input_text {
  &_wrapper{
    position: relative;
    flex: 1;

    &.type_focus{
      .input_text{
        &_title{
          top: 1.2rem;
          @include fontStyleGroup(null, $F-size-xxsmall, $F-weight-regular, 16px, $color: $C-gray-500);
        }
        padding: 3.1rem 1.6rem 1.1rem;
      }
    }
  }

  &_delete{
    display: inline-block;
    position: absolute;
    z-index: 9999;
    right: 1.6rem;
    bottom: 1.4rem;
    background-image: url('../../assets/images/ico_20_delete.svg');
    font-size: 0;
    text-indent: -99999999px;
    width: $ICON_SIZE_20;
    height: $ICON_SIZE_20;
    background-size: 100%;
    background-position: right top;
    background-repeat: no-repeat;
  }

  &_title{
    @include fontStyleGroup(null, $F-size-big, $F-weight-regular, 24px,  $color: $C-gray-500);
    position: absolute;
    top: 2.2rem;
    left: 1.6rem;
    pointer-events: none;
  }

  &::placeholder {
    opacity: 0;
    @include fontStyleGroup(null, $F-size-big, $F-weight-regular, 24px,  $color: $C-gray-400);
  }

  &:focus {
    @include fontStyleGroup(null, $F-size-big, $F-weight-medium, 24px,  $color: $C-gray-900);
    border: 1px solid $C-gray-900;
    &.input_text::placeholder{
      opacity: 1;
    }
  }

  @include fontStyleGroup(null, $F-size-big, $F-weight-medium, 24px,  $color: $C-gray-900);
  //height: 6.8rem;
  border-radius: 0.8rem;
  border: 1px solid $C-gray-300;
  background-color: $C-white;
  padding: 2.1rem 1.6rem;
  display: block;
  width: 100%;
}