@charset "UTF-8";

// buttons

.btn {
  &_wrap {
    text-align: center;
  }
  &_basic {
    &_default,
    &_primary {
    }

    &_inner {
      width: 100%;
    }
  }

  &_image {
    &_back {
      @include inBlind();
      @include position(null, null, null, 1.2rem);
      @include use-svg(ico_arrow_left, $ICON_SIZE_28, $ICON_SIZE_28, 0 0, $wrap-position: absolute);
    }
    &_close {
      @include inBlind();
      @include position(null, 1.2rem, null, null);
      @include use-svg(ico_exit, $ICON_SIZE_28, $ICON_SIZE_28, 0 0, $wrap-position: absolute);
    }
    &_setting{
      @include inBlind();
      @include use-svg(ico_setting_solid, $ICON_SIZE_28, $ICON_SIZE_28, 0 0);
    }
    &_reload{
      @include inBlind();
      @include use-svg(Circle, $ICON_SIZE_28, $ICON_SIZE_28, 0 0);
    }
  }

  &_round {
    &_default {
      padding: 1.4rem;
      text-align: center;
      border-radius: 0.8rem;
      @include fontStyleGroup(null, $F-size-big, $F-weight-regular, 24px, $color: $C-gray-900);
      background-color: $C-gray-200;
      &:active{
      }
      &:disabled,
      &.disabled{
      }
    }

    &_primary {
      padding: 1.4rem;
      text-align: center;
      border-radius: 0.8rem;
      //height: 5.2rem;
      @include fontStyleGroup(null, $F-size-big, $F-weight-medium, 24px, $color: $C-white);
      background-color: $C-primary-500;
      &:active{
        //background-color: $C-primary-500;
        //color:$C-white;
      }
      &:disabled,
      &.disabled{
        color: $C-gray-400;
        background-color: $C-gray-100;
      }
    }
  }

  &_logout{
    width: 100%;
    background: $C-white;
    padding: 2rem;
    text-align: left;
    @include fontStyleGroup(null, $F-size-big, $F-weight-regular, 24px, null, $C-gray-900);
    @extend .ico_arrow_right;
    &:active{
      background: rgba($C-gray-700, 0.15);
    }
    &:disabled,
    &.disabled{
    }
  }
}